.account-linking {
  background-color: #101011;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh; /* Ensures the container takes up the full viewport height */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  width: 100%;
  align-items: center;
  padding: 80px 80px 142px;
}

.background-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.linking-section {
  position: relative;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  margin-bottom: -28px;
  width: 100%;
  max-width: 1304px;
  margin-top: 190px;
  margin-right: 140px;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.section-title {
  color: #fff;
  letter-spacing: 1.8px;
  text-align: center;
  font: 500 36px Gordita, sans-serif;
}

.success-message {
  align-self: stretch;
  margin-top: 44px;
  color: #00ff29;
  text-align: center;
  padding: 14px 70px;
  font: 500 24px Gordita, sans-serif;
}

.accounts-container {
  margin-top: 50px;
  width: 696px;
  max-width: 100%;
  display: flex;
  gap: 20px;
}

.account-card {
  border-radius: 20px;
  border: 1px solid #00a3ff;
  box-shadow: 0 0 25px rgba(0, 163, 255, 0.25);
  background-color: #0a0a0c;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  font-family: Gordita, sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 50px 0 20px;
}

.account-type {
  align-self: stretch;
  font-size: 24px;
  position: relative;
  color: #ffffff !important; /* Force the blue text color */
  font-size: 24px;
  text-align: center;
  padding: 14px 70px;
  font-weight: 500;
  background: radial-gradient(circle, rgba(0, 87, 255, 0.2) 0%, rgba(0, 87, 255, 0.045) 50%, rgba(0, 87, 255, 0.003) 100%);
  border-radius: 0px;
  padding: 14px 70px;
}

.account-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 104px;
  margin-top: 25px;
  max-width: 100%;
}

.account-info {
  font-size: 20px;
  margin-top: 30px;
}

.account-id {
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #fff;
}

.actions-container {
  display: flex;
  margin-top: 84px;
  width: 780px;
  max-width: 100%;
  gap: 20px;
  color: #f6f4f9;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Gordita, sans-serif;
}

.action-button {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 18px 36px;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}

.discord-join {
  border: 1px solid #00a3ff;
  box-shadow: 0 0 25px rgba(75, 187, 253, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 18px;
  padding: 15px 42px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background: linear-gradient(90deg, #00a3ff 0%, #0057ff 100%);
  color: #fff;
  font-weight: 500;
}

.boost-status {
  border: 1px solid #00a3ff;
  box-shadow: 0 0 25px rgba(75, 187, 253, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 18px;
  padding: 15px 42px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background: linear-gradient(90deg, #ff00c8 0%, #ff0022 100%);
  color: #fff;
  font-weight: 500;
}

.unlink-accounts {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 15px;
  font-size: 18px;
  padding: 15px 42px;
  margin-top: 20px;
  box-shadow: 0 0 25px rgba(218, 33, 40, 0.25);
  background: linear-gradient(90deg, #b63727 0%, #c02216 100%);
}

@media (max-width: 991px) {
  .content-wrapper {
    max-width: 100%;
    padding: 0 20px 100px;
  }

  .linking-section {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .success-message {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }

  .accounts-container {
    margin-top: 40px;
    flex-direction: column;
  }

  .account-type {
    padding: 0 20px;
  }

  .actions-container {
    margin-top: 40px;
  }

  .action-button {
    padding: 18px 20px;
  }
}
