/* Main Page and Container */
.link-account-page {
  background-color: #101011;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  position: relative;

}

.background-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.content-container {
  position: relative;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 146px;
  width: 100%;
  max-width: 1304px;
}

/* Title and Success Message */
.page-title {
  color: #fff;
  letter-spacing: 1.8px;
  text-align: center;
  font: 500 36px Gordita, sans-serif;
}

.success-message {
  position: relative;
  color: #00A3FF !important; /* Force the blue text color */
  font-size: 24px;
  text-align: center;
  padding: 14px 70px;
  font-weight: 500;
  background: radial-gradient(circle, rgba(0, 87, 255, 0.2) 0%, rgba(0, 87, 255, 0.045) 50%, rgba(0, 87, 255, 0.003) 100%);
  /* Adding opacity gradient to the message box itself */
  border-radius: 0px; /* No border-radius, squared edges */
  
  margin: 44px 0;
}

.success-message::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle, rgba(0, 87, 255, 0.1) 0%, rgba(0, 87, 255, 0.2) 70%, rgba(0, 87, 255, 0) 100%);
  /* Full opacity in the center, increasing transparency at the edges */
  border-radius: 0px; /* Ensure squared edges */
  z-index: -1; /* Keeps this layer behind the text */
}

/* Account Card Styles */
.accounts-container {
  display: flex;
  gap: 50px; /* Adjusted gap to move cards apart */
  margin-top: 50px;
}

.account-card {
  width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Gordita, sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
}

.account-card.steam {
  border: 1px solid #00a3ff;
  box-shadow: 0 0 25px 0 rgba(0, 163, 255, 0.25);
}

.account-card.discord {
  border: 1px solid #5865F2;
  box-shadow: 0 0 25px 0 rgba(88, 101, 242, 0.25);
}

/* Avatar and Account Info */
.account-avatar {
  width: 100px;
  height: 100px;
  margin-top: 25px;
  object-fit: contain;
}

.account-info {
  font-size: 20px;
  margin-top: 30px;
}

.account-id {
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.7);
}

/* Button Styling */
.link-button {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 18px;
  box-shadow: 0 0 25px 0 rgba(0, 163, 255, 0.25);
  padding: 15px 42px;
  margin-top: 30px; /* Increased margin to push button down */
  cursor: pointer;
  border: none;
  background: linear-gradient(90deg, #00a3ff 0%, #0057ff 100%);
  color: #fff;
  font-weight: 500;
}

.link-button.linked {
  background: linear-gradient(90deg, #00C11F 0%, #008916 100%);
}

.link-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.buttonText {
  flex-grow: 1;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .link-account-page {
    padding: 20px;
  }

  .content-container {
    padding: 50px 20px 100px;
  }

  .accounts-container {
    flex-direction: column;
    align-items: center;
  }

  .account-card {
    width: 100%;
    max-width: 338px;
  }

  .link-button {
    padding: 15px 20px;
  }
}
